// export const URL = "http://localhost:8000/";
// export const THIS_URL = "http://localhost:3000/";

export const URL = "https://api.doutorasgottardo.com.br/"
export const THIS_URL = "https://www.doutorasgottardo.com.br/"

// export const URL = "https://rttesteapi.enterscience.com.br/"
// export const THIS_URL = "https://rtteste.enterscience.com.br/"

export const API_URL = URL + "api/";
export const STORAGE_URL = URL + "storage/";

//KEYS PARA BUSCAR OS ESTILOS DAS PÁGINAS

export const PAGE_KEYS = {
  header: "header",
  login: "login",
  register: "register",
  landing: "landing",
  mural_cursos: "mural_cursos",
};
